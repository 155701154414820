import { createRouter, createWebHistory } from 'vue-router';

const LoginPage = () => import('@/views/LoginPage.vue');
const HomePage = () => import('@/views/HomePage.vue');
const MenuPage = () => import('@/views/MenuPage.vue');
const ReservationsPage = () => import('@/views/ReservationsPage.vue');
const ContactPage = () => import('@/views/ContactPage.vue');
const PrivacyPolicyPage = () => import('@/views/PrivacyPolicyPage.vue');
const LegalNoticePage = () => import('@/views/LegalNoticePage.vue');
const AboutPage = () => import('@/views/AboutPage.vue');
const AdminPage = () => import('@/views/AdminPage.vue');
const AdminMenuPage = () => import('@/views/AdminMenuPage.vue');
const AdminHoursPage = () => import('@/views/AdminHoursPage.vue');

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/menu',
    name: 'MenuPage',
    component: MenuPage,
  },
  {
    path: '/reservations',
    name: 'ReservationsPage',
    component: ReservationsPage,
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
  },
  {
    path: '/legal-notice',
    name: 'LegalNoticePage',
    component: LegalNoticePage,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    // Ajouter un meta pour indiquer que cette page nécessite une authentification
    meta: { requiresAuth: true },
  },
  {
    path: '/adminMenu',
    name: 'AdminMenuPage',
    component: AdminMenuPage,
    // Ajouter un meta pour indiquer que cette page nécessite une authentification
    meta: { requiresAuth: true },
  },
  {
    path: '/adminClosures',
    name: 'AdminHoursPage',
    component: AdminHoursPage,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Ajout de la gestion du comportement de défilement
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Si on revient en arrière, on restaure la position précédente
    } else {
      return { top: 0, behavior: 'smooth' }; // Remet à zéro l'overflow avec un effet smooth
    }
  },
});

// Vérification de l'authentification avant chaque navigation
router.beforeEach((to, from, next) => {
  // Si la route nécessite une authentification et que l'utilisateur n'est pas authentifié
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.getItem('user')) {
      next({ name: 'LoginPage' }); // Redirige vers la page de connexion
    } else {
      next(); // Continue normalement si l'utilisateur est authentifié
    }
  } else {
    next(); // Continue si aucune authentification n'est nécessaire
  }
});

export default router;
